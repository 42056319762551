import React, { useState, useRef } from 'react';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';

function Home() {
  return (
    <div className='bg-black min-h-screen flex justify-center h-full w-full overflow-x-hidden'>
      <div className='w-full sm:w-1/3 px-6 sm:px-0 overflow-x-hidden'>
        <span className='mt-10 absolute py-4 flex border w-fit bg-gradient-to-r blur-xl from-blue-500 via-teal-500 to-pink-500 bg-clip-text text-6xl box-content font-extrabold text-transparent text-center select-none'>
          Siddhanth Kumar
        </span>
        <h1 className='mt-10 relative top-0 w-fit h-auto py-4 justify-center flex bg-gradient-to-r items-center from-blue-500 via-teal-500 to-pink-500 bg-clip-text text-6xl font-extrabold text-transparent text-center select-auto'>
          Siddhanth Kumar
        </h1>
        <p className='text-white mt-5'>
          I'm a 2nd year Computer Science Student at Purdue.
        </p>
        <div className='flex mt-5 gap-3 flex-wrap'>
          {' '}
          <a
            href='https://www.linkedin.com/in/siddhanth-kumar-3897b5205/'
            target='_blank'
            className='flex items-center gap-2'
          >
            <span className='flex items-center group'>
              <ArrowOutwardIcon className='text-white' />
              <p className='text-white group-hover:underline group-hover:underline-offset-4 transition-all'>
                LinkedIn
              </p>
            </span>
          </a>
          <a
            href='https://github.com/sidgithub780'
            target='_blank'
            className='flex items-center gap-2'
          >
            <span className='flex items-center group'>
              <ArrowOutwardIcon className='text-white' />
              <p className='text-white group-hover:underline group-hover:underline-offset-4 transition-all'>
                GitHub
              </p>
            </span>
          </a>
          <a
            href='https://x.com/sidkumars23'
            target='_blank'
            className='flex items-center gap-2'
          >
            <span className='flex items-center group'>
              <ArrowOutwardIcon className='text-white' />
              <p className='text-white group-hover:underline group-hover:underline-offset-4 transition-all'>
                Twitter
              </p>
            </span>
          </a>
          <a
            href='https://youtube.com/@siddhanthkumar'
            target='_blank'
            className='flex items-center gap-2'
          >
            <span className='flex items-center group'>
              <ArrowOutwardIcon className='text-white' />
              <p className='text-white group-hover:underline group-hover:underline-offset-4 transition-all'>
                Youtube
              </p>
            </span>
          </a>
        </div>
        <p className='text-white text-3xl font-bold mt-10 mb-5'>Experiences</p>
        <p className='text-white text-xl'>
          Robinhood - Software Engineer Intern
        </p>
        <p className='text-gray-400'>Incoming Summer 2025</p>
        <p className='text-white text-xl mt-5'>
          Panasonic Avionics - Software Engineer Intern
        </p>
        <p className='text-gray-400'>
          Wrote a few backend functions and developed a payment processing
          drop-in widget to enable airlines to accept ancillary purchases,
          reduced airline integration time by ~60%.
        </p>
        <p className='text-white mt-5 text-xl flex flex-row'>
          <a href='https://jumpseatapp.com' target='_blank'>
            <span className='flex group items-center'>
              <p className='text-white group-hover:underline group-hover:underline-offset-4 transition-all'>
                Jumpseat
              </p>
              <ArrowOutwardIcon className='text-white' fontSize='medium' />
            </span>
          </a>
          - Software Engineer
        </p>
        <p className='text-gray-400'>
          Worked on Portal by Jumpseat, an all-in-one platform for shuttle
          businesses. Wrote backend routes and frontend components to handle
          shuttle routes, user bookings, and trips.
        </p>
        <p className='text-white mt-5 text-xl'>
          Yamaha Precision Propellers - Data Science Researcher
        </p>
        <p className='text-gray-400'>
          Wrote Python with Pandas to conduct analysis of key performance
          indicators from Yamaha's investment casting foundry & implemented an
          Extratrees classification model to predict scrap.
        </p>
        <p className='text-white text-3xl font-bold mt-10 mb-5'>Projects</p>
        <a href='https://devpost.com/software/tablify-v1562o' target='_blank'>
          <span className='flex group items-center'>
            <p className='text-white group-hover:underline group-hover:underline-offset-4 transition-all text-xl'>
              Tablify
            </p>
            <ArrowOutwardIcon className='text-white' fontSize='medium' />
          </span>
        </a>
        <p className='text-gray-400'>
          <span className='bg-gradient-to-t font-bold font-style: italic from-green-500 to-indigo-400 text-transparent bg-clip-text'>
            Won 1st place at SB Hacks IX.{' '}
          </span>
          Built a Chrome extension with my friends that automatically organizes
          tabs by analyzing their content and grouping them by topic through
          natural language processing and sentinent analysis.
        </p>
        <a
          href='https://youtu.be/jP2KgZb76sA?si=zehYFaiQpZje-AIx'
          target='_blank'
        >
          <span className='flex group items-center mt-5'>
            <p className='text-white group-hover:underline group-hover:underline-offset-4 transition-all text-xl'>
              Melanomore
            </p>
            <ArrowOutwardIcon className='text-white' fontSize='medium' />
          </span>
        </a>
        <p className='text-gray-400'>
          Engineered a mobile app to use pictures of skin moles to determine
          benign or malignant for melanoma. Developed a CNN to reach ~90%
          accuracy on binary image classification.
        </p>
        <a
          href='https://youtu.be/YY2IEuKwg0w?si=OEDjUo6TNgauDE86'
          target='_blank'
        >
          <span className='flex group items-center mt-5'>
            <p className='text-white group-hover:underline group-hover:underline-offset-4 transition-all text-xl'>
              TFTI - Thanks for the Invite
            </p>
            <ArrowOutwardIcon className='text-white' fontSize='medium' />
          </span>
        </a>
        <p className='text-gray-400 mb-10'>
          Developed an event management system using React Native, Material UI,
          NodeJS, and Firebase.
        </p>
      </div>
    </div>
  );
}

export default Home;
